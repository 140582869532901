import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import { get, map, compose } from 'lodash/fp'
import { nodes } from 'helpers'

import { Flex } from 'system'
import { BoundedBox } from 'src/components/BoundedBox'
import { Layout } from 'src/components/Layout'
import { Physician } from 'src/components/Physician'

import { PageTitle } from 'src/slices/PageTitle'
import { Summary } from 'src/slices/Summary'

const OurTeamPage = ({ data }) => {
  const page = get('file.childMarkdownRemark', data)
  const physicians = compose(
    nodes,
    get('physicians')
  )(data)

  return (
    <Layout>
      <Helmet>
        <title>{get('frontmatter.title', page)}</title>
      </Helmet>
      <PageTitle>{get('frontmatter.title', page)}</PageTitle>
      <Summary markdown={get('frontmatter.description', page)} />
      <BoundedBox color="navy">
        <Flex as="ul" flexWrap="wrap">
          {map(
            physician => (
              <Physician
                as="li"
                key={get('id', physician)}
                imageFluid={get(
                  'frontmatter.photo.childImageSharp.fluid',
                  physician
                )}
                name={get('frontmatter.title', physician)}
                path={get('frontmatter.path', physician)}
                phoneNumber={get('frontmatter.phoneNumber', physician)}
                specialties={get('frontmatter.specialties', physician)}
                mb={6}
                size="large"
                width={[1, 1 / 3]}
                px={[null, 4]}
              />
            ),
            physicians
          )}
        </Flex>
      </BoundedBox>
    </Layout>
  )
}

export default OurTeamPage

export const query = graphql`
  query OurTeamPage {
    file(relativePath: { eq: "customPages/our-team.md" }) {
      childMarkdownRemark {
        frontmatter {
          title
          description
        }
      }
    }
    physicians: allMarkdownRemark(
      sort: { fields: [frontmatter___order] }
      filter: { fileAbsolutePath: { regex: "/(physicians)/.*.md$/" } }
    ) {
      edges {
        node {
          id
          frontmatter {
            path
            title
            photo {
              childImageSharp {
                fluid(maxWidth: 800, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            specialties
            emailAddress: email_address
            phoneNumber: phone_number
          }
        }
      }
    }
  }
`
